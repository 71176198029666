/*******************************************************************************
*  Index
*******************************************************************************
*/
$(document).ready(function(){

 validaNews();
 validaContatoTopo();
 validaContatoFooter();

     //Dont Drag Images
     $('img').on('dragstart', function(event) { event.preventDefault(); });

    //Fill Images into block, support IE 6
    $(".imgLiquidFillDefault").imgLiquid({
      fill: true,
      horizontalAlign: "center",
      verticalAlign: "center"
    });
    $("input[type='text']").label_better({
      position: "top", // This will let you define the position where the label will appear when the user clicked on the input fields. Acceptable options are "top", "bottom", "left" and "right". Default value is "top".
      animationTime: 500, // This will let you control the animation speed when the label appear. This option accepts value in milliseconds. The default value is 500.
      easing: "bounce", // This option will let you define the CSS easing you would like to see animating the label. The option accepts all default CSS easing such as "linear", "ease" etc. Another extra option is you can use is "bounce". The default value is "ease-in-out".
      offset: 0, // You can add more spacing between the input and the label. This option accepts value in pixels (without the unit). The default value is 20.
      hidePlaceholderOnFocus: true // The default placeholder text will hide on focus
    });
    $("textarea").label_better({
      position: "top", // This will let you define the position where the label will appear when the user clicked on the input fields. Acceptable options are "top", "bottom", "left" and "right". Default value is "top".
      animationTime: 500, // This will let you control the animation speed when the label appear. This option accepts value in milliseconds. The default value is 500.
      easing: "bounce", // This option will let you define the CSS easing you would like to see animating the label. The option accepts all default CSS easing such as "linear", "ease" etc. Another extra option is you can use is "bounce". The default value is "ease-in-out".
      offset: 0, // You can add more spacing between the input and the label. This option accepts value in pixels (without the unit). The default value is 20.
      hidePlaceholderOnFocus: true // The default placeholder text will hide on focus
    });
    //Scroll intent da página
    // $(function () {
    //   $.scrollUp({
    //     scrollText: '<i class="icon-angle-up" alt="Voltar ao Topo"></i>'
    //   });
    // });

    // Only runs when look at the image
   //  echo.init({
   //    offset: 0,
   //    throttle: 250,
   //    unload: false,
   //    debounce: false,
   //    callback: function (element, op) {
   //     $(element).parent().addClass('imgLiquidFill');
   //     //Fill Images into block, support IE 6
   //     $(".imgLiquidFill").imgLiquid({
   //      fill: true,
   //      horizontalAlign: "center",
   //      verticalAlign: "center"
   //    });
   //   }
   // });

        //Scroll show header script
        var header = document.querySelector("#header");
        if(window.location.hash) {
          header.classList.add("headroom--unpinned");
        }
        var headroom = new Headroom(header, {
          tolerance: {
            down : 5,
            up : 10
          },
          offset : 123
        });
        headroom.init();
        //Controla a visão de altura do header para o headroom e fixed
        $('body').css('padding-top', $('#header').height() + 'px', 'important');
        new ResizeSensor(jQuery('#header'), function() {
          $('body').css('padding-top', $('#header').height() + 'px', 'important');
        });

	// Parallax
	$('.bgParallax').each(function(){
		var $obj = $(this);
    var offCanvas = $('.off-canvas-content');
    var windowItem = $(window);
    $(window).scroll(function() {
     var yPos = -($(window).scrollTop() / $obj.data('speed'));
     var bgpos = yPos + 'px';
     $obj.css('top', bgpos );
   });
  });
	//A link href smooth
	$('a[href*=#]:not([href=#])').click(function(event) {
    //Melhorar o script pois ele não aceita realizar o scroll em uma página interna por conta de algo na condição abaixo
		//if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html,body').animate({
					//I put the size of the fixed here
					scrollTop: (target.offset().top)-40
				}, 900);
				return false;
			}
		//}
	});
	//Open and Close Responsive itens
	$('.open-close').click(function() {
		$(this).find('.item-to-open').toggle("slide");
	});

  //Mascaras de telefone
  $('#frmContatoTopo #telefone, #frmContatoFooter #telefone').mask('(99) 9999-9999?9');

  //Aplicando mascaras
  jQuery(function($) {
    $.mask.definitions['~']='[+-]';
      //Inicio Mascara Telefone
      $('#frmContatoTopo #telefone, #frmContatoFooter #telefone').focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
          element.mask("(99) 99999-999?9");
        } else {
          element.mask("(99) 9999-9999?9");
        }
      }).trigger('focusout');
    });
});

//chamada das validações
  // validaNews();
  // validaContato();
  // validaCurriculo();

/*******************************************************************************
*  Header
*******************************************************************************
*/
// Duplicar o menu dentro do off-canvas para o responsivo
$(function(){
  //var $menu = $('header #menu').children().clone(true,true);
  //$('#offCanvas .menuAccordion').append($menu);
  //var options = {};
  //var elem = new Foundation.Drilldown($('#offCanvas .content-inside .menuAccordion'), options);
});
// $(window).on('opened.zf.offcanvas', function() {
//   $('#banner .banner-item.bgParallax').css('top', '0px !important');
// });
// $(window).on('closed.zf.offcanvas', function() {
//   $('#banner .banner-item.bgParallax').attr('style', '');
// });
// $(function(){
// 	$('#header #menu').slicknav({
// 		duration: 800,
// 		prependTo: "#header .menu-responsivo",
// 		init: function() {
// 			// $("#header .slicknav_menutxt").html("<i class='fa fa-bars'></i>");
// 			$("#header .slicknav_icon").html("<span class='slicknav_icon-bar first'></span><span class='slicknav_icon-bar second'></span><span class='slicknav_icon-bar third'></span>")
// 		}
// 	});

// 	var content = $('#header .slicknav_nav').html();
// 	$('#header .slicknav_nav').html("");
// 	$('#header .slicknav_nav').prepend("<div class='mininav'></div>");
// 	$('#header .slicknav_nav .mininav').html(content);

// 	$("<button type='button' class='overlay-close'>Close</button>").prependTo('#header .slicknav_nav');

// 	$('#header .overlay-close').click(function(event) {
// 		$('#header #menu').slicknav('close');
// 	});
// 	$('#header .slicknav_nav li a').click(function(event) {
// 		$('#header #menu').slicknav('close');
// 	});
// });

/*******************************************************************************
*  Home
********************************************************************************/
if(document.getElementById('home')){
  $(document).ready(function(){
  });
}

/*******************************************************************************
*  Banner
********************************************************************************/
if(document.getElementById('banner')){
  $(document).ready(function(){
    $('#banner .slide-banner').slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 700,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      //cssEase: 'linear',
      cssEase: 'ease-out',
      pauseOnFocus: false,
      pauseOnHover: false,
    });
  });
}

/*******************************************************************************
*  Bloco Slide Empresas
********************************************************************************/
$(document).ready(function(){
  $('.mini-banners .slide-images').on('lazyLoaded', function (e, slick, img, src) {
    $(img).parent().addClass('imgLiquidFill');
    $(img).parent().imgLiquid({
      fill: true,
      horizontalAlign: "center",
      verticalAlign: "center"
    });
  });
  $('.mini-banners .slide-images').slick({
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    swipeToSlide : true,
    fade: true,
    cssEase: 'cubic-bezier(0.86, 0.1, 0.17, 1)',
    speed: 600,
    //autoplay: true,
    //autoplaySpeed: 5000,
    touchThreshold: 70,
    prevArrow: '<div class="snick-btn left"></div>',
    nextArrow: '<div class="snick-btn right"></div>',
    lazyLoad: 'ondemand',
    asNavFor: '.mini-banners .slide-text',
    responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    }
    ]
  });
  $('.mini-banners .slide-text').slick({
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    swipeToSlide : false,
    swipe: false,
    touchThreshold: 70,
    speed: 600,
    asNavFor: '.mini-banners .slide-images',
    cssEase: 'cubic-bezier(0.86, 0.1, 0.17, 1)'
  });
});

/*******************************************************************************
*  Interna Grupos
********************************************************************************/
$(document).ready(function(){
 $('.interna-grupo .galeria-fotos .slide').slick({
  arrows: true,
  dots: false,
  infinite: true,
  slidesToShow: 4,
  speed: 700,
  swipeToSlide : true,
  autoplay: true,
  autoplaySpeed: 5000,
  touchThreshold: 70,
  arrows: false,
  pauseOnHover: false,
  pauseOnFocus: false,
  prevArrow: '<div class="snick-btn left"><button type="button" class="slick-prev"><span>+</span></button></div>',
  nextArrow: '<div class="snick-btn right"><button type="button" class="slick-next"><span>+</span></button></div>',
  responsive: [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    }
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
  ]
});
 $('.gallery-photo').each(function() {
  $(this).magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled:true
    }
  });
});
});
/*******************************************************************************
*  Detalhes Empresa
********************************************************************************/
if(document.getElementById('detalhes-empresa')){

}
/*******************************************************************************
*  Google 360 Propaganda home
********************************************************************************/
if(document.getElementById('destaques-bloco')){

}
/*******************************************************************************
*  Bloco Destaque topo home
********************************************************************************/
if(document.getElementById('destaques-bloco')){

}


/*******************************************************************************
*  Página Produtos
********************************************************************************/

if(document.getElementById('produtos')){
}

/*******************************************************************************
*  Página Detalhes
********************************************************************************/

if(document.getElementById('paginaDetalhes')){

}

/*******************************************************************************
*  Fotos
********************************************************************************/

if(document.getElementById('fotos')){
  $(document).ready(function() {

  });
}

/*******************************************************************************
*  Videos
********************************************************************************/

if(document.getElementById('videos')){
  $(document).ready(function() {
   $('.gallery').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });
 });
}

/*******************************************************************************
*  footer
********************************************************************************/

if(document.getElementById('footer')){
  $(document).ready(function() {
    //Create accordion in footer.
    function createAccordion(){
      //Verify if foundation exist to create an instance
      if (!$('footer .accordion').data('zfPlugin')) {
        $('footer .accordion').foundation();
      }
      var $elem = new Foundation.Accordion($('footer .accordion'), 'data-allow-all-closed: true');
      $('footer .accordion').foundation('up', $('footer .accordion-content'));
    }
    //Destroy accordion in footer.
    function destroyAccordion(){
      //Let it active before destroy
      $('footer .accordion .accordion-item').addClass("is-active");
      //Verify if foundation accordion exist
      if ($('footer .accordion').data('zfPlugin')) {
        $('footer .accordion').foundation('destroy');
      }
      //Clean css inline of accordion and Make it open in large screen after destroy
      $('footer .accordion .accordion-content').attr('style', 'display:block;');
    }
    //Check if is small or medium to keep closed.
    if(Foundation.MediaQuery.current == 'medium' || Foundation.MediaQuery.current == 'small'){
      createAccordion();
      $('footer .accordion').foundation('up', $('footer .accordion-content'));
    }
    //Destroy if larger or bigger
    if (Foundation.MediaQuery.atLeast('large')) {
      destroyAccordion();
    }
    //Watch if screen change to create or destroy elements.
    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
      if(newSize == "small" || newSize == "medium"){
        createAccordion();
      }else{
        //Bug because it doesn't know if is initialized already.
        destroyAccordion();
      }
    });
  });
}

$(function(){
  if(document.getElementById('interna-trabalho')){

    $('#frmCurriculo #telefone').mask('(99) 9999-9999?9');
    $('#frmCurriculo #cpf').mask('999.999.999-99');
    $('#frmCurriculo #data_nasc').mask('99/99/9999');

    //Aplicando mascaras
    jQuery(function($) {
      $.mask.definitions['~']='[+-]';
      //Inicio Mascara Telefone
      $('#frmCurriculo #telefone').focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
          element.mask("(99) 99999-999?9");
        } else {
          element.mask("(99) 9999-9999?9");
        }
      }).trigger('focusout');
    });

    //Termos
    $("#termos").click(function(){
      if($(this).is(':checked')){
        $('#aceito').val('1');
      }else{
        $('#aceito').val('');
      }
    });
  }
  if(document.getElementById('interna-contato')){

    $('#frmContato #telefone').mask('(99) 9999-9999?9');

    //Aplicando mascaras
    jQuery(function($) {
      $.mask.definitions['~']='[+-]';
      //Inicio Mascara Telefone
      $('#frmContato #telefone').focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
          element.mask("(99) 99999-999?9");
        } else {
          element.mask("(99) 9999-9999?9");
        }
      }).trigger('focusout');
    });
  }
});


/*******************************************************************************
*  Simulação de Digitação
*******************************************************************************/
var TxtType = function(el, toRotate, period) {
  this.toRotate = toRotate;
  this.el = el;
  this.loopNum = 0;
  this.period = parseInt(period, 10) || 2000;
  this.txt = '';
  this.tick();
  this.isDeleting = false;
};

TxtType.prototype.tick = function() {
  var i = this.loopNum % this.toRotate.length;
  var fullTxt = this.toRotate[i];

  if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
  } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
  }

  this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

  var that = this;
  var delta = 200 - Math.random() * 100;

  if (this.isDeleting) { delta /= 2; }

  if (!this.isDeleting && this.txt === fullTxt) {
    delta = this.period;
    this.isDeleting = true;
  } else if (this.isDeleting && this.txt === '') {
    this.isDeleting = false;
    this.loopNum++;
    delta = 500;
  }

  setTimeout(function() {
    that.tick();
  }, delta);
};

window.onload = function() {
  var elements = document.getElementsByClassName('typewrite');
  for (var i=0; i<elements.length; i++) {
    var toRotate = elements[i].getAttribute('data-type');
    var period = elements[i].getAttribute('data-period');
    if (toRotate) {
      new TxtType(elements[i], JSON.parse(toRotate), period);
    }
  }
};

/*******************************************************************************
*  Conversão de SVG dentro de classes dinâmicas
*******************************************************************************/
$(document).ready(function(){
  $(".inline-svg").each(function(){
    var cur=$(this);
    var w=cur.data("width");
    var h=cur.data("height");
    cur.css({
      display:"inline-block",
      verticalAlign:"middle",
      lineHeight:0
    });
    if(typeof(w)=="undefined"){
      w=cur.width();
    }
    if(typeof(h)=="undefined"){
      h=cur.height();
    }
    cur.css({
      width:w,
      height:h
    });

    var svg=Snap(w,h);

    var setW=w!=0;
    var setH=h!=0;
    Snap.load(cur.data("src"),function(f){
      svg.append(f);
      var container=cur.children("svg");
      var loaded=container.children("svg");
      var originalW=parseFloat(loaded.attr("width"));
      var originalH=parseFloat(loaded.attr("height"));
      var viewBox=loaded[0].getAttribute('viewBox').split(" ");
      if(isNaN(originalW)){
        originalW=parseFloat(viewBox[2]);
      }
      if(isNaN(originalH)){
        originalH=parseFloat(viewBox[3]);
      }
      if(!setW){
        w=originalW;
        container.attr("width",w);
      }else{
        loaded.attr("width",w);
        if(!setH){
          h=originalH*(w/originalW);
          container.attr("height",h);
          setH=true;
        }
      }
      if(!setH){
        h=originalH;
        container.attr("height",h);
      }else{
        loaded.attr("height",h);
        if(!setW){
          w=originalW*(h/originalH);
          container.attr("width",w);
          loaded.attr("width",w);
        }
      }

      cur.css({
        width:w,
        height:h
      })

      cur.data("loaded",true)
      cur.trigger("load");
    })

    svg.appendTo(cur.get(0));
    cur.data("snap",svg);
  })
})

/*******************************************************************************
*  3D elements on mouse movement
********************************************************************************/
$(document).ready(function(){
  var main = document.querySelector('#banner .row');
  document.addEventListener('mousemove', function (e) {
    var w = window.innerWidth,
    h = window.innerHeight,
    rx = (h / 2 - e.pageY) / 20,
    ry = (w / 2 - e.pageX) / 140;

    main.style.transform = 'rotateX(' + rx + 'deg) rotateY(' + -ry + 'deg)';
    //mainImg.style.transform = 'translate3d(' + ry * 3 + 'px, ' + rx * 3 + 'px, 0)';
  });
});

/*******************************************************************************
*  Modais
********************************************************************************/
$(document).ready(function(){
  $(".menutopo").animatedModal({
    modalTarget: 'menutopo',
    color: '#00F695',
    animatedIn: 'slideInDown',
    animatedOut: 'slideOutUp',
    afterClose: function() {
    }
  });
});

/*******************************************************************************
*  Animações do mouse
********************************************************************************/
var scene = $('#banner').get(0);
var parallax = new Parallax(scene);



/*******************************************************************************
*  Configurações e Plugins
*******************************************************************************
*/
$(document).ready(function(){
var animacoesReadyPreloader = anime.timeline();
animacoesReadyPreloader.add({
  targets: '#preloader #logo path, #preloader #janela .dash-stroke',
  begin: function(anim) {
    var size = this.animatables.length;
    for(var i = 0; i < size; i++) {
      this.animatables[i].target.setAttribute("stroke", "#000000");
    }
  },
  complete: function(anim) {
   var checkExist = setInterval(function() {
     if ($('body.pace-done').length) {
      afterFirstAnimation();
      clearInterval(checkExist);
    }
  }, 100);
 },
 strokeDashoffset:{
  value: function(el) {
        return [el.getAttribute('stroke-dasharray'), 0]
    },
  easing: 'easeInOutSine',
  duration: 1500,
  delay: 300,
},
}).add({
  targets: '#preloader #logo path',
  fillOpacity:{
    value: [1],
    easing: 'easeInOutSine',
    duration: 500,
  },
  strokeOpacity:{
    value: [0],
    easing: 'easeInOutSine',
    duration: 500,
  },
});


/*******************************************************************************
*  Executa somente após terminar as animações
*******************************************************************************/
function afterFirstAnimation() {
  /*******************************************************************************
  *  SVG Animações - easing: [0.86, 0, 0.07, 1],
  *******************************************************************************/
  setTimeout(function(){
    $(window).scrollTop(0);

    $('#preloader').velocity("slideUp", 500);

    var animacoesReady = anime.timeline();
    animacoesReady.add({
      targets: '#header #logo path',
      begin: function(anim) {
        var size = this.animatables.length;
        for(var i = 0; i < size; i++) {
          this.animatables[i].target.setAttribute("stroke", "#000000");
        }
      },
      strokeDashoffset:{
        value: function(el) {
        return [el.getAttribute('stroke-dasharray'), 0]
    },
        easing: 'easeInOutSine',
        duration: 1600,
        delay: 400,
      },
    }).add({
      targets: '#header #logo path',
      fillOpacity:{
        value: [1],
        easing: 'easeInOutSine',
        duration: 500,
      },
      strokeOpacity:{
        value: [0],
        easing: 'easeInOutSine',
        duration: 500,
      },
    });

    var animacoesReadyBanner = anime.timeline();
    animacoesReadyBanner.add({
      targets: '#banner #janela .dash-stroke, #banner #circulo-x .dash-stroke',
      begin: function(anim) {
        var size = this.animatables.length;
        for(var i = 0; i < size; i++) {
          this.animatables[i].target.setAttribute("stroke", "#000000");
        }
      },
      strokeDashoffset:{
        value: function(el) {
        return [el.getAttribute('stroke-dasharray'), 0]
    },
        easing: 'easeInOutSine',
        duration: 1700,
        delay: 400,
      },
    });

    sr.reveal('#header .animation', { origin: 'left', distance: '50px', delay: 170}, 50);

    sr.reveal('#banner .risco-left-animation, #banner .bg-verde-animation', { origin: 'left', distance: '750px', delay: 470, viewFactor  : 0.1});
    sr.reveal('#banner .risco-bottom-animation', { origin: 'right', distance: '750px', delay: 470, viewFactor  : 0.1});
    sr.reveal('#banner .risco-bottom .mini-risco', { origin: 'right', distance: '150px', delay: 550, viewFactor  : 0.1}, 190);
    sr.reveal('#banner .circle-risk-animation', { origin: 'bottom', distance: '200px', delay: 470, viewFactor  : 0.1});

    sr.reveal('.slide-text', { origin: 'top', distance: '170px', delay: 470});
    sr.reveal('.slide-text .item > div', { origin: 'bottom', distance: '120px', delay: 670}, 100);
    sr.reveal('.slide-images', { origin: 'bottom', distance: '170px', delay: 170});

    sr.reveal('.clientes .titulo', { origin: 'left', distance: '50px', delay: 370});
    sr.reveal('.clientes .column-block', { origin: 'right', distance: '200px', delay: 470}, 100);

    sr.reveal('.portfolio .column-block', { origin: 'left', distance: '200px', delay: 270}, 120);

    sr.reveal('.newsletter .bloco-input > *', { origin: 'left', distance: '200px', delay: 130}, 322);

    sr.reveal('footer .accordion', { origin: 'left', distance: '200px', delay: 30}, 122);
  }, 500);
}

});

/*******************************************************************************
*  Foundation Call
********************************************************************************/
$(document).foundation();

